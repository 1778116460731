export const logos = [
	{
		title: 'Death of Someone',
		href: 'death',
		column: 1,
		row: 1,
	},
	{
		title: 'Contractor',
		href: 'contractor',
		column: 1,
		row: 2,
	},
	{
		title: 'Deed Transfer',
		href: 'deed',
		column: 1,
		row: 3,
	},
]
